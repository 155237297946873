<template>
  <v-data-iterator
    :items="items"
    sort-by="id_str"
    :search="search"
    :sort-desc="sortDesc"
  >
    <template v-slot:header>
      <v-toolbar
        dark
        color="gray darken-3"
        class="mb-1"
      >
        <v-text-field
          v-model="search"
          clearable
          flat
          solo-inverted
          hide-details
          prepend-inner-icon="mdi-magnify"
          label="Search"
        />
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-spacer />
          <v-btn-toggle
            v-model="sortDesc"
            mandatory
          >
            <v-btn
              large
              depressed
              color="gray"
              :value="false"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn
              large
              depressed
              color="gray"
              :value="true"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-btn-toggle>
        </template>
      </v-toolbar>
    </template>

    <template v-slot:default="props">
      <!-- <v-select
        v-model="selectedId"
        item-text="user.name"
        item-value="user.name"
        :items="items"
        label="ユーザーで絞る"
        v-on:change="changeUser(selectedId)"
      /> -->
      <v-row dense>
        <v-col
          v-for="item in props.items"
          :key="item.id_str"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <tweet
            :id="item.id_str"
            error-message=" "
            :options="{ align: 'center' }"
          >
            <spinner />
          </tweet>
        </v-col>
      </v-row>
    </template>
  </v-data-iterator>
</template>

<script>
  import { Tweet } from 'vue-tweet-embed'
  import axios from 'axios'
  import Spinner from 'vue-simple-spinner'
  export default {
    name: 'TweetView',
    components: {
      Tweet,
      Spinner,
    },
    data: function () {
      return {
        items: [],
        // selectedId: "",
        sortDesc: true,
        search: '',
      }
    },
    created: function () {
      axios.get(`../../gradually_tweet.json?timestamp=${new Date().getTime()}`).then(res => {
        this.items = res.data
      // res.data.forEach(data => {
      //   let inputdata;
      //   inputdata = data.quoted_status
      //   inputdata.user_name = data.quoted_status.user.name;
      //   inputdata.user_screen_name = data.quoted_status.user.screen_name;
      //   inputdata.user_id_str = data.quoted_status.user.id_str;
      //   this.items.push(inputdata);
      // });
      })
    },
  // methods: {
  //   changeUser(selectedId) {
  //     //this.$router.push({path: a })
  //     console.log(selectedId);
  //   }
  // }
  }
</script>
